@import url('https://rsms.me/inter/inter.css');

@font-face {
  font-family: 'OffBit';
  src: url('../public/OffBit.otf') format('opentype'); /* URL is relative to the public folder */
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: 'Amiri', serif;
  color: white;
  background: black;
  background: radial-gradient(circle at bottom center, rgba(60, 60, 60, 1) 0%, rgba(0, 0, 0, 1) 75%);
  /* cursor: url('https://github.com/chenglou/react-motion/raw/master/demos/demo8-draggable-list/cursor.png') 39 39, auto; */
  /* cursor: url('https://public.blenderkit.com/thumbnails/assets/082c7417bca0408b918e4ef85061f9a9/files/thumbnail_8ef673c2-d99d-4523-bcb9-35991d2c25d6.png.256x256_q85_crop-%2C.png') 39 39, auto; */
  font-family: inter;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 1s ease 0s forwards;
}

.annotation {
  transition: all 0.5s ease-in-out;
  box-shadow: inset 0 0 0 0.09px white;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 8px;
  font-weight: 300;
  background: rgb(88, 88, 88);
  color: white;
  padding: 2px 10px;
  border-radius: 20px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.annotation:hover {
  box-shadow: inset 250px 0 0 0 white;
  color: black;
}

.loader {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 900;
}

.header {
  pointer-events: none;
  position: absolute;
  top: 6em;
  left: 0;
  color: white;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 900;
  font-size: 0.8em;
  width: 100%;
  text-align: center;
}

.header > span {
  padding: 0 3.25em;
  letter-spacing: 0.4em;
  position: relative;
}

.header > span.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: calc(50% - 4px);
  transform: translate3d(-50%, 0, 0);
  width: 20px;
  height: 2px;
  background: indianred;
}

.middle {
  pointer-events: none;
  position: absolute;
  bottom: 2.5em;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  font-size: 2em;
  letter-spacing: 0.5em;
  text-align: center;
  width: 100%;
}

.middle > h1 {
  line-height: 0.9em;
  font-weight: 100;
}

.middle > h1::after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: calc(50% - 25px);
  width: 50px;
  height: 2px;
  background: white;
}

.middle > h2 {
  font-size: 1em;
  line-height: 0px;
  font-weight: 100;
  letter-spacing: 0.25em;
  font-style: italic;
}
